<template>
	<div>
		<HeadCard />
		<a-card title="邀请码" class="context">
			<a-table :columns="columns" :data-source="data">
				<a-button slot="action" type="primary" v-clipboard:error="onError" v-clipboard:copy="invitationCodeURL" v-clipboard:success="onCopy">复制邀请信息</a-button>
			</a-table>
		</a-card>
		<a-card title="推荐记录" class="context">
			<a-table
				:loading="loading"
				:total="pagination.total"
				@change="handleTableChange"
				v-model="pagination.current"
				:columns="columns2"
				:pagination="pagination"
				:data-source="data2"
			>
				<span slot="type" slot-scope="text">
					{{ status[text] }}
					<span />
				</span>
				<span slot="traffic_gb" slot-scope="text">
					{{ text }}GB
					<span />
				</span>
			</a-table>
		</a-card>
		<Footer />
	</div>
</template>

<script>
import HeadCard from '../../components/HeadCard'
import Footer from '../../components/Footer'
import api from '../../api/api'
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('userInfo')
const columns = [
	{
		title: 'ID',
		dataIndex: 'id',
		key: 'id',
		align: 'center'
	},
	{
		title: '邀请码',
		dataIndex: 'invitationCode',
		key: 'invitationCode',
		align: 'center'
	},
	{
		title: 'Action',
		dataIndex: '',
		key: 'action',
		ellipsis: true,
		align: 'center',
		scopedSlots: { customRender: 'action' }
	}
]
const columns2 = [
	{
		title: 'ID',
		dataIndex: 'id',
		key: 'id',
		align: 'center'
	},
	{
		title: '用户ID',
		dataIndex: 'invite_email',
		key: 'invite_email',
		align: 'center'
	},
	{
		title: '奖励时间',
		dataIndex: 'timestamp',
		key: 'timestamp',
		customRender: text => {
			return moment(text).format('YYYY-MM-DD HH:mm:ss')
		},
		align: 'center'
	},
	{
		title: '奖励流量',
		dataIndex: 'traffic_gb',
		key: 'traffic_gb',
		align: 'center',
		scopedSlots: { customRender: 'traffic_gb' }
	},
	{
		title: '奖励类型',
		dataIndex: 'type',
		key: 'type',
		align: 'center',
		scopedSlots: { customRender: 'type' }
	}
]

export default {
	components: {
		HeadCard,
		Footer
	},
	data() {
		return {
			data: [],
			data2: [],
			columns,
			loading: false,

			columns2,
			status: {
				register: '注册奖励',
				payment: '充值奖励',
				direct: '直接奖励'
			},
			invitationCodeURL: '',
			//分页设置
			pagination: {
				pageSize: 10, // 默认每页显示数量
				showTotal: total => `共 ${total} 条`, // 显示总数
				total: 10, //总条数
				current: 1 //按钮页码
			}
		}
	},
	created() {
		this.invitationHistory(this.pagination.current), this.getUserInfo()
	},
	mounted() {
		this.data.invitationCode = this.userInfo.invitation_code
		this.data.push({ invitationCode: this.userInfo.invitation_code, id: this.userInfo.email })
		this.setBaseUrl()
	},
	computed: {
		...mapState(['userInfo'])
	},
	methods: {
		handleTableChange(pagination) {
			this.pagination = pagination
			this.invitationHistory(pagination.current)
		},
		async invitationHistory(current) {
			this.loading = true
			const data = await api.invitationHistory(current)
			if (data.count>=0) {
				this.loading = false
			}
			this.pagination.total = data.count
			this.data2 = data.list
		},
		...mapActions(['getUserInfo']),
		onCopy(e) {
			console.log('e', e)
			this.$message.success('已成功复制到剪贴板')
		},
		// 复制失败
		onError(e) {
			console.log('e', e)
			this.$message.error('复制失败')
		},
		setBaseUrl() {
			this.invitationCodeURL = `${this.CONSTNAME.hostURL}#/user/register?code=${this.data.invitationCode}`.replace(/\s*/g, '')
			console.log(this.invitationCodeURL)
		}
	}
}
</script>

<style scoped lang="scss">
.context {
	width: 65%;
	margin: 20px auto;
}
</style>
